import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Linkedin, Github, Headphones, Twitter, Music, ExternalLink, Moon, Sun } from 'lucide-react';
import { useDarkMode } from './DarkModeContext';

interface ExternalLinkIconProps {
  href: string;
  children: ReactNode;
}

const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = ({ href, children }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="hover:underline"
  > 
    {children}
  </a>
);

const MainPage: React.FC = () => {
  const { darkMode, toggleDarkMode } = useDarkMode();

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-900 dark:to-gray-800 min-h-screen font-sans text-gray-800 dark:text-gray-200">
      <header className="max-w-4xl mx-auto p-6">
        <nav className="flex justify-between items-center mb-12">
          <div className="space-x-6">
            <Link to="/" className="font-bold text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors">home</Link>
            <Link to="/projects" className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors">projects</Link>
            <Link to="/volunteering" className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors">volunteering</Link>
          </div>
          <button onClick={toggleDarkMode} className="p-2 rounded-full bg-gray-200 dark:bg-gray-700">
            {darkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
        </nav>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img src="/1677561732317.jpeg" alt="Marcelo Barrera" className="w-32 h-32 rounded-full mr-6 shadow-lg object-cover" />
            <div>
              <h1 className="text-3xl font-bold text-blue-800 dark:text-blue-400">Marcelo Barrera</h1>
              <p className="text-lg text-blue-600 dark:text-blue-300">BTE @ NYU Stern</p>
              <div className="flex space-x-3 mt-3">
                <a href="https://www.instagram.com/marcelobarreracv" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"><Instagram size={24} /></a>
                <a href="https://twitter.com/MooseTrivia" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"><Twitter size={24} /></a>
                <a href="https://www.linkedin.com/in/marcelo-barrera/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"><Linkedin size={24} /></a>
                <a href="https://open.spotify.com/user/marcelobarrera03" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"><Music size={24} /></a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-4xl mx-auto p-6">
        <section className="mb-12 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-bold mb-4 text-blue-800 dark:text-blue-400">Education</h2>
          <div>
            <h3 className="font-semibold text-lg text-gray-800 dark:text-gray-200">New York University, Leonard N. Stern School of Business</h3>
            <p className="text-blue-600 dark:text-blue-300">B.S. in Business, Technology, and Entrepreneurship</p>
            <p className="text-gray-600 dark:text-gray-400">Sep 2022 - May 2026</p>
            <div className="mt-2">
              <p className="font-medium">Achievements & Involvements:</p>
              <p className="text-sm mt-1">
              <>
          <p>BTE Data Sprint Winner</p>
          <p>Social Director, BTE Major</p>
          <p>Startup Team Consultant, Entrepreneurial Exchange Group</p>
            </> </p>
            </div>
            <div className="flex flex-wrap gap-2 mt-3">
              {['Projects in Programming & Data Science', 'BTE Startup Lab', 'NYC Entrepreneurship Lab', 'Foundations of Finance'].map((course) => (
                <span key={course} className="bg-blue-100 dark:bg-gray-700 text-blue-800 dark:text-blue-400 px-3 py-1 rounded-full text-sm font-medium">{course}</span>
              ))}
            </div>
          </div>
        </section>

        <section className="mb-12 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-bold mb-4 text-blue-800 dark:text-blue-400">Work Experience</h2>
          <div className="space-y-6">
            <div>
              <ExternalLinkIcon href="https://www.beatsbydre.com/"><h3 className="font-semibold text-lg">Beats By Dre</h3></ExternalLinkIcon>
              <p className="text-blue-600 dark:text-blue-300">Business Analytics Extern</p>
              <p className="text-gray-600 dark:text-gray-400">May 2024 - July 2024</p>
              <p className="text-sm mt-2">Conducted comprehensive customer discovery for portable Bluetooth speakers, leveraging data analytics to drive product strategy and market positioning.</p>
            </div>
            <div>
            <ExternalLinkIcon href="https://www.plugandplaytechcenter.com/"><h3 className="font-semibold text-lg">Plug and Play Tech Center</h3></ExternalLinkIcon>
              <p className="text-blue-600 dark:text-blue-300">University Program Fellow</p>
              <p className="text-gray-600 dark:text-gray-400">Oct 2023 - May 2024</p>
              <p className="text-sm mt-2">Engaged in rigorous weekly VC training, evaluating 35 startups weekly and participating in bi-weekly pitch events.</p>
            </div>
            <div>
            <h3 className="font-semibold text-lg">
              <ExternalLinkIcon href="https://twitter.com/TriviaMoose">
                Moose Trivia
              </ExternalLinkIcon>
            </h3>
            <p className="text-blue-600 dark:text-blue-300">Founder</p>
            <p className="text-gray-600 dark:text-gray-400">March 2019 - April 2023</p>
            <p className="text-sm mt-2">
              Developed a specialized algorithm for HQ Trivia, scaling to $30,000 MRR and 1,000+ customers with 10ms latency. 
              Managed all business aspects including strategy, finance, and product development.
            </p>
          </div>
            <div>
              <h3 className="font-semibold text-lg">Limitless Shoes</h3>
              <p className="text-blue-600 dark:text-blue-300">Sole Proprietor</p>
              <p className="text-gray-600 dark:text-gray-400">Nov 2017 – Dec 2022</p>
              <p className="text-sm mt-2">Leveraged e-commerce automation to achieve $400,000+ in sales, sourcing limited edition products and managing 2,000+ inventory items efficiently.</p>
            </div>
          </div>
        </section>

        <section className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-bold mb-4 text-blue-800 dark:text-blue-400">Skills</h2>
          <div className="flex flex-wrap gap-3">
            {['Python', 'Data Analysis', 'Product Management', 'Agile Methodologies', 'User Research', 'Wireframing', 'A/B Testing', 'SQL', 'Tableau', 'Google Analytics', 'Jira', 'Confluence', 'Figma', 'Tailwind CSS', 'React', 'Node.js', 'Git', 'Financial Modeling', 'Market Research', 'Strategic Planning'].map((skill) => (
              <span key={skill} className="bg-blue-100 dark:bg-gray-700 text-blue-800 dark:text-blue-400 px-3 py-1 rounded-full text-sm font-medium">{skill}</span>
            ))}
          </div>
        </section>
      </main>

      <footer className="max-w-4xl mx-auto p-6 text-center text-gray-600 dark:text-gray-400 mt-12">
        <p></p>
      </footer>
    </div>
  );
};

export default MainPage;