import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';
import { useDarkMode } from './DarkModeContext';

interface ExternalLinkIconProps {
    href: string;
    children: ReactNode;
  }
  
  const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = ({ href, children }) => (
    <a 
      href={href} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="hover:underline"
    >
      {children}
    </a>
  );
    
const VolunteeringPage: React.FC = () => {
  const { darkMode } = useDarkMode();

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-900 dark:to-gray-800 min-h-screen font-sans text-gray-800 dark:text-gray-200">
      <header className="max-w-4xl mx-auto p-6">
        <nav className="flex justify-between items-center mb-12">
          <div className="space-x-6">
            <Link to="/" className="text-blue-600 hover:text-blue-800 transition-colors">home</Link>
            <Link to="/projects" className="text-blue-600 hover:text-blue-800 transition-colors">projects</Link>
            <Link to="/volunteering" className="font-bold text-blue-600 hover:text-blue-800 transition-colors">volunteering</Link>
          </div>
        </nav>
        <h1 className="text-3xl font-bold text-blue-800 dark:text-blue-400">Volunteering</h1>
      </header>

      <main className="max-w-4xl mx-auto p-6">
        
        <section className="mb-12 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
        <ExternalLinkIcon href="https://twitter.com/chefsanta2021"><h2 className="text-2xl font-bold mb-4 text-blue-800 dark:text-blue-400">Chef Santa</h2></ExternalLinkIcon>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Spearheaded a high-impact charity initiative, raising $106,000 in just 5 days. Engaged 60+ companies, secured sponsorships, and leveraged social media for extensive marketing reach, resulting in significant engagement and Twitter impressions.
          </p>
        </section>

        <section className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
        <ExternalLinkIcon href="https://marinebiologycamp.net/"><h2 className="text-2xl font-bold mb-4 text-blue-800 dark:text-blue-400">Gerard Loisel's Marine Biology Camp</h2></ExternalLinkIcon>
            Served as a counselor since the age of 15, contributing to marine biology education during summer sessions. This long-term commitment has fostered a deep understanding of marine ecosystems and helped inspire the next generation of marine biologists.
        </section>
      </main>

      <footer className="max-w-4xl mx-auto p-6 text-center text-gray-600 dark:text-gray-400 mt-12">
        <p></p>
      </footer>
    </div>
  );
};

export default VolunteeringPage;