import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';
import { useDarkMode } from './DarkModeContext';

interface ExternalLinkIconProps {
  href: string;
  children: ReactNode;
}

const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = ({ href, children }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="hover:underline"
  >
    {children}
  </a>
);

const ProjectsPage: React.FC = () => {
  const { darkMode } = useDarkMode();

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-900 dark:to-gray-800 min-h-screen font-sans text-gray-800 dark:text-gray-200">
      <header className="max-w-4xl mx-auto p-6">
        <nav className="flex justify-between items-center mb-12">
          <div className="space-x-6">
            <Link to="/" className="text-blue-600 hover:text-blue-800 transition-colors">home</Link>
            <Link to="/projects" className="font-bold text-blue-600 hover:text-blue-800 transition-colors">projects</Link>
            <Link to="/volunteering" className="text-blue-600 hover:text-blue-800 transition-colors">volunteering</Link>
          </div>
        </nav>
        <h1 className="text-3xl font-bold text-blue-800 dark:text-blue-400">Projects</h1>
      </header>

      <main className="max-w-4xl mx-auto p-6">
        <section className="mb-12 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
        <ExternalLinkIcon href="https://www.sizematterschipotle.com/"><h2 className="text-2xl font-bold mb-4 text-blue-800 dark:text-blue-400">Size Matters, Chipotle</h2></ExternalLinkIcon>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Platform designed to rate Chipotle restaurants based on portion sizes, enhancing customer experience and value perception. Over 19,000 ratings have been submitted, providing valuable insights to Chipotle customers and management.
          </p>
        </section>

        <section className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
        <ExternalLinkIcon href="https://create-and-conserve.com/"><h2 className="text-2xl font-bold mb-4 text-blue-800 dark:text-blue-400">Create & Conserve</h2></ExternalLinkIcon>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Platform enabling content creators to efficiently diversify their reach by automatically redistributing TikTok content across multiple short-form video platforms. This innovative solution optimizes content strategy, maximizes audience engagement, and streamlines the content creation process for creators.
          </p>
        </section>
      </main>

      <footer className="max-w-4xl mx-auto p-6 text-center text-gray-600 dark:text-gray-400 mt-12">
        <p></p>
      </footer>
    </div>
  );
};

export default ProjectsPage;